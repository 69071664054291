import Cookies from "js-cookie";

export function GetS3PresignedPostUrl(fileName, bucket) {
  return new Promise((resolve, reject) => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    fetch(process.env.GATSBY_USER_SERVICE_API + "/jobs/presign-post", {
      method: "post",
      body: JSON.stringify({ fileName, bucket }),
    })
      .then((response) => {
        if (response.status !== 200) {
          reject(response.statusText);
        }
        resolve(response);
      })
      .catch((e) => reject(e));
  });
}
export function UploadFileToS3(presignedPostData, file) {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    Object.keys(presignedPostData.fields).forEach((key) => {
      formData.append(key, presignedPostData.fields[key]);
    });
    // Actual file has to be appended last.
    formData.append("file", file);
    fetch(presignedPostData.url, {
      method: "post",
      body: formData,
    })
      .then((response) => {
        if (response.status === 204) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch((e) => reject(e));
  });
}

export function JobApply(data, path = "/jobs/apply") {
  return new Promise((resolve, reject) => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    fetch(process.env.GATSBY_USER_SERVICE_API + path, {
      method: "post",
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status !== 200) {
          reject(response.statusText);
        }
        resolve(response);
      })
      .catch((e) => reject(e));
  });
}
export function GetOfferData(id) {
  return new Promise((resolve, reject) => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    fetch(process.env.GATSBY_USER_SERVICE_API + `/offer/${id}`, {
      method: "get",
    })
      .then((response) => {
        if (response.status !== 200) {
          reject(response.statusText);
        }
        resolve(response);
      })
      .catch((e) => reject(e));
  });
}

export function HireUs(data) {
  return new Promise((resolve, reject) => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    fetch(process.env.GATSBY_USER_SERVICE_API + `/contact/hire`, {
      method: "post",
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status !== 200) {
          reject(response.statusText);
        }
        resolve(response);
      })
      .catch((e) => reject(e));
  });
}

export function PartnerWithUs(data) {
  return new Promise((resolve, reject) => {
    let headers = new Headers();
    headers.append("Content-Type", "application/json");
    fetch(process.env.GATSBY_USER_SERVICE_API + `/contact/partner`, {
      method: "post",
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status !== 200) {
          reject(response.statusText);
        }
        resolve(response);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Checks to see if a cookie with a country value exists. If there is a value, it just returns it.
 * But if not, it makes a HTTP GET request via fetch to the root page so that we can retrieve the country headers
 * and sets the value in a cookie so we don't make extra fetch calls.
 * @returns Promise
 */
export async function GetLocationCountry() {
  const countryCookie = Cookies.get("country-cookie");
  if (countryCookie && countryCookie != "null") return countryCookie;
  else {
    return new Promise((resolve, reject) => {
      fetch(`/`, {
        method: "get",
      })
        .then((response) => {
          if (response.status !== 200) {
            reject(response.statusText);
          }
          const country = response?.headers?.get("Country");
          Cookies.set("country-cookie", country);
          resolve(country);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
}
