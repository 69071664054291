import { GetLocationCountry } from "./src/lib/services/http.service";

/** @type {import("gatsby").GatsbyBrowser["shouldUpdateScroll"]} */
export function shouldUpdateScroll() {
  return false;
}

/** @type {import("gatsby").GatsbyBrowser["onClientEntry"]} */
export async function onClientEntry() {
  GetLocationCountry().catch((error) =>
    console.error("Error fetching location country:", error)
  );
  if ("serviceWorker" in navigator) {
    try {
      const registration = await navigator.serviceWorker.register("/sw.js");
    } catch (error) {
      console.error(`Registration failed with ${error}`);
    }
  }
}
